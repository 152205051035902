import React from 'react';
import angel from "../../Images/others/angel.gif";
import grid from "../../Images/others/grid-drawing.gif";
import alebrije from "../../Images/others/alebrije-painting.jpg";
import japanesePrintDrawing from "../../Images/others/japanese-print.gif";
import skull from "../../Images/others/skull.gif";





const images = [
  { src: angel, alt: 'angel1' },
  { src: grid, alt: 'grid1' },
  { src: alebrije, alt: 'alebrije-painting' },
  { src: japanesePrintDrawing, alt: 'japanes-print-drawing' },
  { src: skull, alt: 'skull' },
];

export const OtherIllustrations = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default OtherIllustrations;
