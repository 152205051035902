import React from 'react';
import inDesignIcon from "../../Images/icons/in-design-icon.jpeg"; // Replace with your InDesign icon path
import pdfIcon from "../../Images/icons/pdf-icon.jpeg"; // Replace with your PDF icon path
import inDesignLayout from "../../Images/graphic-design/in-design/adam-jones-tool-in-design-layout.indd";
import inDesignPdf from "../../Images/graphic-design/in-design/in-design-layout-adam-jones-tool.pdf";







const images = [
    { src: inDesignIcon, alt: 'InDesign layout', link: inDesignLayout },
    { src: pdfIcon, alt: 'PDF', link: inDesignPdf },
  ];

  export const InDesign = () => {
    const handleImageClick = (link) => {
      const anchor = document.createElement('a');
      anchor.href = link;
      anchor.download = link.split('/').pop(); // Use the image name for download
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };
  
    return (
        <div className="grid-parent">
        <div className="grid-container">
          {images.map((image, index) => (
            <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
              <img className="grid-img" src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
        </div>
      );
    };
  
  export default InDesign;