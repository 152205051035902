import React from 'react';
import '../../Style.css';
import BnsTrailerFunny from "../../Videos/animation/barbos-and-shah-trailer-mexican-voice.mp4";
import BnsTrailer from "../../Videos/animation/barbos-and-shah-trailer.mp4";
import Goaler1 from "../../Videos/goaler-final-videos/goaler-exercise-ad-final.mp4";
import Goaler2 from "../../Videos/goaler-final-videos/goaler-finance-ad-finale.mp4";
import Goaler3 from "../../Videos/goaler-final-videos/goaler-kid-time-ad-finale.mp4";
import Goaler4 from "../../Videos/goaler-final-videos/goaler-making-friends-ad-finale.mp4";
import Goaler5 from "../../Videos/goaler-final-videos/goaler-meditation-ad-final.mp4";
import Goaler6 from "../../Videos/goaler-final-videos/goaler-music-lessons-ad-finale.mp4";
import Goaler7 from "../../Videos/goaler-final-videos/goaler-pets-ad-finale.mp4";
import Goaler8 from "../../Videos/goaler-final-videos/goaler-parenting-ad-finale.mp4";
import Goaler9 from "../../Videos/goaler-final-videos/goaler-relationships-ad-final.mp4";
import Goaler10 from "../../Videos/goaler-final-videos/goaler-date-night-ad-finale.mp4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export const VideoProduction = () => (
    <div className="2d-animations">
        <div className="carousel-outer">
            <div className="carousel">
                <Carousel>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={BnsTrailerFunny} type="video/mp4" />
                        </video>
                        <p className="video-description">Foley, SFX, Dialogue, Mix, Animation, FX</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={BnsTrailer} type="video/mp4" />
                        </video>
                        <p className="video-description">Foley, SFX, Dialogue, Mix, Animation, FX</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler1} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler2} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler3} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler4} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler5} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler6} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler7} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler8} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler9} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Goaler10} type="video/mp4" />
                        </video>
                        <p className="video-description">Broadcast Levels, Video Editing, Audio Editing, Animation</p>
                    </div>

                </Carousel>
            </div>
        </div>
    </div>
);

export default VideoProduction;
