import React from 'react';
import betsy1 from "../../Images/figure-drawing-60-min/betsy-60 min-dec-modified.jpg";
import burt1 from "../../Images/figure-drawing-60-min/Burt-60min-modified.jpg";
import maddy1 from "../../Images/figure-drawing-60-min/maddy-60 min-dec.jpg";
import nick1 from "../../Images/figure-drawing-60-min/nick-60min-dec-modified.jpg";
import nick2 from "../../Images/figure-drawing-60-min/nick-60min.jpg";

const images = [
  { src: betsy1, alt: 'bet60min1' },
  { src: burt1, alt: 'bu60min1' },
  { src: maddy1, alt: 'mad60min1' },
  { src: nick1, alt: 'ni60min1' },
  { src: nick2, alt: 'ni60min2' },
];

export const SixtyMinuteGestures = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default SixtyMinuteGestures;
