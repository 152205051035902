import React from 'react';
import sb1 from "../../Images/storyboards/geico-storyboard.gif";
import sb2 from "../../Images/storyboards/oreo-storyboard.jpg";
import sb3 from "../../Images/storyboards/wile-coyote-storyboard.gif";
const images = [
  { src: sb1, alt: 'storyboard1' },
  { src: sb2, alt: 'storyboard2' },
  { src: sb3, alt: 'storyboard3' },
];

export const Storyboards = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default Storyboards;
