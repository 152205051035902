import React from 'react';
import beige from "../../Images/graphic-design/alebrije-patterns/alebrije-beige.jpg";
import purple from "../../Images/graphic-design/alebrije-patterns/alebrije-purple.jpg";
import red from "../../Images/graphic-design/alebrije-patterns/alebrije-red.jpg";
import blue from "../../Images/graphic-design/alebrije-patterns/alebrije-blue.jpg";







const images = [
  { src: beige, alt: 'beige1' },
  { src: purple, alt: 'purple1' },
  { src: red, alt: 'red1' },
  { src: blue, alt: 'blue1' },

];

export const AlebrijePatterns = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


    
    return (
        <div className="grid-parent">
        <div className="grid-container">
          {images.map((image, index) => (
            <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
              <img className="grid-img" src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
        </div>
      );
    };
export default AlebrijePatterns;
