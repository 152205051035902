import React from 'react';
import '../../Style.css';
import TheRoomFoley from "../../Audio/audioreel.mp4";
import AudioReel from "../../Audio/foley-the-room.mp4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export const AudioProduction = () => (
    <div className="2d-animations">
        <div className="carousel-outer">
            <div className="carousel">
                <Carousel>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={AudioReel} type="video/mp4" />
                        </video>
                        <p className="video-description">Foley, SFX</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={TheRoomFoley} type="video/mp4" />
                        </video>
                        <p className="video-description">Foley, SFX, On-Set Recording, ADR, Mixing</p>
                    </div>
                </Carousel>
            </div>
        </div>
    </div>
);

export default AudioProduction;
