import React from 'react';
import hockney from "../../Images/graphic-design/photography/david-hockney-project.gif";
import forestPark from "../../Images/graphic-design/photography/forest-park.gif";
import autumnRooftop from "../../Images/graphic-design/photography/autumn-rooftop.gif";
import girlWithViolin from "../../Images/graphic-design/photography/girl-with-violin.gif";
import mosphireForPortfolio from "../../Images/graphic-design/photography/mosphire-for-portfolio.jpg";
import panorama from "../../Images/graphic-design/photography/panorama.gif";







const images = [
  { src: hockney, alt: 'hockney1' },
  { src: forestPark, alt: 'forestPark1' },
  { src: autumnRooftop, alt: 'autumnRooftop1' },
  { src: girlWithViolin, alt: 'forestPark1' },
  { src: mosphireForPortfolio, alt: 'autumnRooftop1' },
  { src: panorama, alt: 'forestPark1' },

];

export const Photography = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default Photography;
