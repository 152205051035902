import React from 'react';
import dreamScene from "../../Images/graphic-design/graphic-illustrations/dream-scene.jpg";
import icons from "../../Images/graphic-design/graphic-illustrations/icons.jpg";
import nashvillePoster from "../../Images/graphic-design/graphic-illustrations/nashville-poster.gif";
import stLouisPrint from "../../Images/graphic-design/graphic-illustrations/st-louis-print.jpg";
import vectorDrawing from "../../Images/graphic-design/graphic-illustrations/vector-drawing.gif";





const images = [
  { src: dreamScene, alt: 'dream-scene1' },
  { src: icons, alt: 'icons1' },
  { src: nashvillePoster, alt: 'nashville-poster' },
  { src: stLouisPrint, alt: 'japanes-print-drawing' },
  { src: vectorDrawing, alt: 'skull' },
];

export const GraphicIllustrations = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
  );
};

export default GraphicIllustrations;
