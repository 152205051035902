import React from 'react';
import face1 from "../../Images/faces/face1.jpg";
import face2 from "../../Images/faces/face2.jpg";
import face3 from "../../Images/faces/face3.jpg";
import face4 from "../../Images/faces/face4.jpg";
import face5 from "../../Images/faces/face5.jpg";
import face6 from "../../Images/faces/face6.jpg";
import face7 from "../../Images/faces/face7.jpg";


const images = [
  { src: face1, alt: 'facel1' },
  { src: face2, alt: 'facel2' },
  { src: face3, alt: 'facel3' },
  { src: face4, alt: 'facel4' },
  { src: face5, alt: 'facel5' },
  { src: face6, alt: 'facel6' },
  { src: face7, alt: 'facel7' },
];

export const Faces = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default Faces;
