import React from 'react';
import '../../Style.css';
import BnsTrailerFunny from "../../Videos/animation/barbos-and-shah-trailer-mexican-voice.mp4";
import BnsTrailer from "../../Videos/animation/barbos-and-shah-trailer.mp4";
import FlowerOfLifeAnimation from "../../Videos/animation/floweroflife.mp4";
import GirlToShiva from "../../Videos/animation/girl-to-shiva.mp4";
import ParvatiDance from "../../Videos/animation/parvati-dance.mp4";
import SnakeBrain from "../../Videos/animation/snake-brain.mov";
import TreeAnimation from "../../Videos/animation/tree-animation.mp4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export const TwoDAnimations = () => (
    <div className="2d-animations">
        <div className="carousel-outer">
            <div className="carousel">
                <Carousel>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={BnsTrailerFunny} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Character Animator, Photoshop, Premiere, Audition</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={BnsTrailer} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Character Animator, Photoshop, Premiere, Audition</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={FlowerOfLifeAnimation} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Photoshop</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={GirlToShiva} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Photoshop</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={ParvatiDance} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Photoshop</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={SnakeBrain} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Photoshop</p>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={TreeAnimation} type="video/mp4" />
                        </video>
                        <p className="video-description">After Effects, Illustrator, Photoshop</p>
                    </div>
                </Carousel>
            </div>
        </div>
    </div>
);

export default TwoDAnimations;
