import React from 'react';
import betsy1 from "../../Images/figure-drawing-20-min/betsy4-20min-nosignature.psd.jpg";
import betsy3 from "../../Images/figure-drawing-20-min/betsy5-20min.psd.jpg";
import brook1 from "../../Images/figure-drawing-20-min/brook-2-20min.psd.jpg";
import brook2 from "../../Images/figure-drawing-20-min/brook6-20min.psd.jpg";
import burt1 from "../../Images/figure-drawing-20-min/burt9-20min.CR2.jpg";
import burt2 from "../../Images/figure-drawing-20-min/burt12-20min.CR2.jpg";
import nick1 from "../../Images/figure-drawing-20-min/nick-20min.CR2.jpg";
import nick2 from "../../Images/figure-drawing-20-min/nick-20min.jpg";
import nick3 from "../../Images/figure-drawing-20-min/nick2-20min.CR2.jpg";
const images = [
  { src: betsy1, alt: 'b20min1' },
  { src: betsy3, alt: 'b20min3' },
  { src: brook1, alt: 'br20min1' },
  { src: brook2, alt: 'br20min2' },
  { src: burt1, alt: 'bu20min1' },
  { src: burt2, alt: 'bu20min2' },
  { src: nick1, alt: 'n20min1' },
  { src: nick2, alt: 'n20min2' },
  { src: nick3, alt: 'n20min3' },

];

export const TwentyMinuteGestures = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default TwentyMinuteGestures;
