import React from 'react';
import shad1 from "../../Images/shadow-line/shadow-line.jpg";
import shad2 from "../../Images/shadow-line/shadow-line2.jpg";
import shad3 from "../../Images/shadow-line/shadow-line3.jpg";

const images = [
  { src: shad1, alt: 'shadl1' },
  { src: shad2, alt: 'shadl2' },
  { src: shad3, alt: 'shadl3' },
];

export const ShadowLine = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default ShadowLine;
