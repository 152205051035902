import React from 'react';
import { Link } from 'react-router-dom';
import '../../Style.css';

function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <i className="fas fa-bars" style={{ color: '#fff' }} />
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav m-auto">
          <li className="nav-item active">
            <Link className="nav-link text-white text-uppercase ml-5" to="/home">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white text-uppercase ml-5" to="/about">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link text-white text-uppercase ml-5" to="/web-development">
              Web Development
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-white text-uppercase ml-5"
              to="/illustrations"
              id="illustrationsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Illustrations
            </Link>
            <div className="dropdown-menu" aria-labelledby="illustrationsDropdown">
              <Link className="dropdown-item" to="/two-minute-gestures">
                2 Minute Gestures
              </Link>
              <Link className="dropdown-item" to="/twenty-minute-gestures">
                20 Minute Gestures
              </Link>
              <Link className="dropdown-item" to="/sixty-minute-gestures">
                60 Minute Gestures
              </Link>
              <Link className="dropdown-item" to="/contour-lines">
                Contour Lines
              </Link>
              <Link className="dropdown-item" to="/shadow-line">
                Shadow Line
              </Link>
              <Link className="dropdown-item" to="/shadow-only">
                Shadow Only
              </Link>
              <Link className="dropdown-item" to="/faces">
                Faces
              </Link>
              <Link className="dropdown-item" to="/feet">
                Feet
              </Link>
              <Link className="dropdown-item" to="/hands">
                Hands
              </Link>
              <Link className="dropdown-item" to="/other-illustrations">
                Other Illustrations
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-white text-uppercase ml-5"
              to="/graphic-design"
              id="graphicDesignDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              3D Models
            </Link>
            <div className="dropdown-menu" aria-labelledby="threeDDropdown">
              <Link className="dropdown-item" to="/substance-painter">
                Substance Painter
              </Link>
              <Link className="dropdown-item" to="/3d-obj-videos">
                Videos
              </Link>
              {/* <Link className="dropdown-item" to="/3d-obj">
                Objects
              </Link> */}
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-white text-uppercase ml-5"
              to="/graphic-design"
              id="graphicDesignDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Graphic Design
            </Link>
            <div className="dropdown-menu" aria-labelledby="graphicDesignDropdown">
              <Link className="dropdown-item" to="/photography">
                Photography
              </Link>
              <Link className="dropdown-item" to="/typography">
                Typography
              </Link>
              <Link className="dropdown-item" to="/in-design">
                In Design
              </Link>
              <Link className="dropdown-item" to="/graphic-illustrations">
                Graphic Illustrations
              </Link>
              <Link className="dropdown-item" to="/alebrije-patterns">
                Alebrije Patterns
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-white text-uppercase ml-5"
              to="/animation"
              id="animationDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Animation
            </Link>
            <div className="dropdown-menu" aria-labelledby="animationDropdown">
              <Link className="dropdown-item" to="/storyboards">
                Storyboards
              </Link>
              <Link className="dropdown-item" to="/2d-animations">
                2D Animations
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle text-white text-uppercase ml-5"
              to="/animation"
              id="animationDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Production
            </Link>
            <div className="dropdown-menu" aria-labelledby="ProductionDropdown">
              <Link className="dropdown-item" to="/audio-production">
                Audio Production
              </Link>
              <Link className="dropdown-item" to="/video-production">
                Video Production
              </Link>
              <Link className="dropdown-item" to="/music-recordings">
                Songs
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
