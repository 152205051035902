import React from 'react';
import Apple1 from "../../Images/threeDObjects/Apple.gif";
import Apple2 from "../../Images/threeDObjects/Apple2.gif";
import Apple3 from "../../Images/threeDObjects/Apple3.gif";
import Log from "../../Images/threeDObjects/Log.gif";
import Log2 from "../../Images/threeDObjects/Log2.gif";
import Log3 from "../../Images/threeDObjects/Log3.gif";
import Bench from "../../Images/threeDObjects/Bench.gif";
import Bench2 from "../../Images/threeDObjects/Bench2.gif";
import Bench3 from "../../Images/threeDObjects/Bench3.gif";
import fireHydrant from "../../Images/threeDObjects/fireHydrant.gif";
import fireHydrant2 from "../../Images/threeDObjects/fireHydrant2.gif";
import fireHydrant3 from "../../Images/threeDObjects/fireHydrant3.gif";
import Lamppost from "../../Images/threeDObjects/Lamppost.gif";
import Lamppost2 from "../../Images/threeDObjects/Lamppost2.gif";
import Lamppost3 from "../../Images/threeDObjects/Lamppost3.gif";


const images = [
  { src: Apple1, alt: 'apple' },
  { src: Apple2, alt: 'apple2' },
  { src: Apple3, alt: 'apple3' },
  { src: Bench, alt: 'bench' },
  { src: Bench2, alt: 'bench2' },
  { src: Bench3, alt: 'bench3' },
  { src: Lamppost, alt: 'lamppost' },
  { src: Lamppost2, alt: 'lamppost2' },
  { src: Lamppost3, alt: 'lamppost3' },
  { src: Log, alt: 'log' },
  { src: Log2, alt: 'log2' },
  { src: Log3, alt: 'log3' },
  { src: fireHydrant, alt: 'fire' },
  { src: fireHydrant2, alt: 'fire2' },
  { src: fireHydrant3, alt: 'fire3' },
];

export const ThreeDobjPictures = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default ThreeDobjPictures;
