import React, { Component } from 'react';
import ReactDOM from "react-dom";
import './Style.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import NavBar from './Components/layouts/NavBar';
import Footer from './Components/layouts/Footer';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from './Components/pages/Home'
import About from './Components/pages/About'
import WebDevelopment from './Components/pages/WebDevelopment';
import TwoMinuteGestures from './Components/pages/TwoMinuteGestures';
import TwentyMinuteGestures from './Components/pages/TwentyMinuteGestures';
import SixtyMinuteGestures from './Components/pages/SixtyMinuteGestures';
import ContourLines from './Components/pages/ContourLines';
import ShadowLine from './Components/pages/ShadowLine';
import ShadowOnly from './Components/pages/ShadowOnly';
import Faces from './Components/pages/Faces';
import Feet from './Components/pages/Feet';
import Hands from './Components/pages/Hands';
import OtherIllustrations from './Components/pages/OtherIllustrations';
import Photography from './Components/pages/Photography';
import Typography from './Components/pages/Typography';
import InDesign from './Components/pages/InDesign';
import GraphicIllustrations from './Components/pages/GraphicIllustrations';
import AlebrijePatterns from './Components/pages/AlebrijePatterns';
import Storyboards from './Components/pages/Storyboards';
import TwoDAnimations from './Components/pages/TwoDAnimations';
import AudioProduction from './Components/pages/AudioProduction';
import VideoProduction from './Components/pages/VideoProduction';
import MusicRecordings from './Components/pages/MusicRecordings';
import ThreeDobjPictures from './Components/pages/ThreeDobjPictures';
import ThreeDobjVideos from './Components/pages/ThreeDobjVideos';

import { Link } from 'react-router-dom';


function App() {
  return (
    
 <div className="home-pic">
    <NavBar />
      <h1 className= 'home-title'>Jonathan Friedberg</h1>
       <h1 className= 'home-title-heading'>Web Developer / Audio Engineer / Visual Artist</h1>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About}/> 
      <Route exact path="/two-minute-gestures" component={TwoMinuteGestures}/>
      <Route exact path="/twenty-minute-gestures" component={TwentyMinuteGestures}/>
      <Route exact path="/sixty-minute-gestures" component={SixtyMinuteGestures}/>
      <Route exact path="/contour-lines" component={ContourLines}/>
      <Route exact path="/shadow-line" component={ShadowLine}/>
      <Route exact path="/shadow-only" component={ShadowOnly}/>
      <Route exact path="/faces" component={Faces}/>
      <Route exact path="/feet" component={Feet}/>
      <Route exact path="/hands" component={Hands}/>
      <Route exact path="/other-illustrations" component={OtherIllustrations}/>
      <Route exact path="/alebrije-patterns" component={AlebrijePatterns}/>
      <Route exact path="/graphic-illustrations" component={GraphicIllustrations}/>
      <Route exact path="/in-design" component={InDesign}/>
      <Route exact path="/photography" component={Photography}/>
      <Route exact path="/typography" component={Typography}/>
      <Route exact path="/storyboards" component={Storyboards}/>
      <Route exact path="/2D-animations" component={TwoDAnimations}/>
      <Route exact path="/audio-production" component={AudioProduction}/>
      <Route exact path="/video-production" component={VideoProduction}/>
      <Route exact path="/music-recordings" component={MusicRecordings}/>
      <Route exact path="/web-development" component={WebDevelopment}/>
      <Route exact path="/substance-painter" component={ThreeDobjPictures}/>
      <Route exact path="/3d-obj-videos" component={ThreeDobjVideos}/>


    </Switch>
    <br></br>
    <br></br>
    <br></br>
    <Footer />
    </div>        
  );
}

export default App;
