import React from 'react';
import audio1 from "../../Audio/borderline.wav"; // Replace with your actual audio files
import audio2 from "../../Audio/road-jam.wav"; // Replace with your actual audio files
import audio3 from "../../Audio/the-garden.mp3"; // Replace with your actual audio files
import audio4 from "../../Audio/come-with-me-something-stars.wav"; // Replace with your actual audio files

const audioFiles = [
  { src: audio1, alt: 'Audio 1' },
  { src: audio2, alt: 'Audio 2' },
  { src: audio3, alt: 'Audio 3' },
  { src: audio4, alt: 'Audio 4' },
];

export const MusicRecordings = () => {
  return (
    <div className="grid-parent">
      <div className="grid-container">
        {audioFiles.map((audio, index) => (
          <div key={index} className="grid-item">
            <audio className="grid-audio" controls>
              <source src={audio.src} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MusicRecordings;
