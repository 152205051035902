import React from 'react';
import DragonHead from "../../Videos/dragon-head.mp4";
import DragonNoWings from "../../Videos/dragon-nowings.mp4";
import Gun from "../../Videos/gun.mp4";
import Jupiter from "../../Videos/jupiter.mp4";
import Earth from "../../Videos/earth.mp4";
import RibCage from "../../Videos/rib-cage-heart-animation.mp4";
import Skull from "../../Videos/skull.mp4";
import Pelvis from "../../Videos/pelvis.mp4";
import MarvelousDesigner from "../../Videos/marvelous-designer.mp4";



import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export const ThreeDobjVideos = () => (
    <div className="2d-animations">
        <div className="carousel-outer">
            <div className="carousel">
                <Carousel>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={DragonHead} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={DragonNoWings} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={RibCage} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Gun} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Jupiter} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Earth} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Skull} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={Pelvis} type="video/mp4" />
                        </video>
                    </div>
                    <div>
                        <video className="fixed_img_three_d" controls>
                            <source src={MarvelousDesigner} type="video/mp4" />
                        </video>
                    </div>                   
                </Carousel>
            </div>
        </div>
    </div>
);

export default ThreeDobjVideos;
