import React from 'react';
import hands1 from "../../Images/hands/hands-page-1.jpg";
import hands2 from "../../Images/hands/hands-page-2.jpg";
import hands3 from "../../Images/hands/hands-page-3.jpg";
import hands4 from "../../Images/hands/hands-page-4.jpg";
import hands5 from "../../Images/hands/hands-page-5.jpg";
import hands6 from "../../Images/hands/hands-page-6.jpg";
import hands7 from "../../Images/hands/hands-page-7.jpg";
import hands8 from "../../Images/hands/hands-page-8.jpg";
import hands9 from "../../Images/hands/hands-page-9.jpg";
import hands10 from "../../Images/hands/hands-page-10.jpg";
import hands11 from "../../Images/hands/hands-page-11.jpg";
import hands12 from "../../Images/hands/hands-page-12.jpg";
import hands13 from "../../Images/hands/hands-page-13.jpg";
import hands14 from "../../Images/hands/hands-page-14.jpg";
import hands15 from "../../Images/hands/hands-page-15.jpg";
import hands16 from "../../Images/hands/hands-page-16.jpg";
import hands17 from "../../Images/hands/hands-page-17.jpg";
import hands18 from "../../Images/hands/hands-page-18.jpg";
import hands19 from "../../Images/hands/hands-page-19.jpg";
import hands20 from "../../Images/hands/hands-page-20.jpg";
import hands21 from "../../Images/hands/hands-page-21.jpg";
import hands22 from "../../Images/hands/hands-page-22.jpg";
import hands23 from "../../Images/hands/hands-page-23.jpg";
import hands24 from "../../Images/hands/hands-page-24.jpg";
import hands25 from "../../Images/hands/hands-page-25.jpg";
import hands26 from "../../Images/hands/hands-page-26.jpg";
import hands27 from "../../Images/hands/hands-page-27.jpg";
import hands28 from "../../Images/hands/hands-page-28.jpg";


const images = [
  { src: hands1, alt: 'handsl1' },
  { src: hands2, alt: 'handsl2' },
  { src: hands3, alt: 'handsl3' },
  { src: hands4, alt: 'handsl4' },
  { src: hands5, alt: 'handsl5' },
  { src: hands6, alt: 'handsl6' },
  { src: hands7, alt: 'handsl7' },
  { src: hands8, alt: 'handsl8' },
  { src: hands9, alt: 'handsl9' },
  { src: hands10, alt: 'handsl10' },
  { src: hands11, alt: 'handsl11' },
  { src: hands12, alt: 'handsl12' },
  { src: hands13, alt: 'handsl13' },
  { src: hands14, alt: 'handsl14' },
  { src: hands15, alt: 'handsl15' },
  { src: hands16, alt: 'handsl16' },
  { src: hands17, alt: 'handsl17' },
  { src: hands18, alt: 'handsl18' },
  { src: hands19, alt: 'handsl19' },
  { src: hands20, alt: 'handsl20' },
  { src: hands21, alt: 'handsl21' },
  { src: hands22, alt: 'handsl22' },
  { src: hands23, alt: 'handsl23' },
  { src: hands24, alt: 'handsl24' },
  { src: hands25, alt: 'handsl25' },
  { src: hands26, alt: 'handsl26' },
  { src: hands27, alt: 'handsl27' },
  { src: hands28, alt: 'handsl28' },
];

export const Hands = () => {
  const handleImageClick = (src) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = src.split('/').pop(); // Use the image name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="grid-parent">
    <div className="grid-container">
      {images.map((image, index) => (
        <div key={index} className="grid-item" onClick={() => handleImageClick(image.src)}>
          <img className="grid-img" src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
    </div>
  );
};

export default Hands;
